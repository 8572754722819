import { combineReducers } from 'redux';
import authReducer from './authReducer';
import userFormsReducer from './userFormsReducer';
import userTooltipsReducer from './userTooltipsReducer';

export default combineReducers({
  auth: authReducer,
  userFormsService: userFormsReducer,
  userTooltipsService: userTooltipsReducer,
});
