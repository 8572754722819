import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { StoreStateTypes } from '../../@types/store';

interface ProtectedRouteProps {
  children: any;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const location = useLocation();
  const auth = useSelector((state: StoreStateTypes) => state.auth);
  // const userHasRequiredRole = !!(auth.user && requiredRoles.includes(auth.user.roles));

  if (!auth.isLoggedIn) {
    return <Navigate to="/sign-in" state={{ from: location.pathname }} />;
  }

  return children;
};

export default ProtectedRoute;
