import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { availableLanguages } from '../../i18n/i18n';
import AccountBar from '../AccountBar/AccountBar';
import SwitchLanguagesButton from '../Buttons/SwitchLanguagesButton/SwitchLanguagesButton';
import Logo from '../Icons/Logo/Logo';
import Navigation from '../Navigation/Navigation';
import './Header.scss';

interface HeaderProps {
  isMobile: boolean;
  toggleMenu: (e: React.MouseEvent<HTMLElement>) => void;
  handleChangeLanguages: (e: React.MouseEvent<HTMLElement>) => void;
}

const Header: FC<HeaderProps> = ({ isMobile, toggleMenu, handleChangeLanguages }) => {
  const { i18n } = useTranslation();
  return (
    <header className={`header ${isMobile ? 'header_type_reverse' : ''}`}>
      <div className="header__content">
        <Logo />
        {!isMobile && <Navigation rowLink isMobile={isMobile} toggleMenu={toggleMenu} />}
        <div className="header__tools">
          {!isMobile && (
            <SwitchLanguagesButton
              i18n={i18n}
              availableLanguages={availableLanguages}
              type="header"
              handleChangeLanguages={handleChangeLanguages}
            />
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
