import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGOUT,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  AUTH_LOADING,
  TOOLTIP_STATE_USER_OPEN,
} from '../types/constants';

import AuthService from '../../services/AuthService';

export const register =
  (name: string, surname: string, email: string, password: string, acceptTerms: boolean) => (dispatch: any) => {
    dispatch({
      type: AUTH_LOADING,
    });
    return AuthService.register(name, surname, email, password, acceptTerms)
      .then((data) => {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: data,
        });
        dispatch({
          type: TOOLTIP_STATE_USER_OPEN,
          payload: {
            status: true,
            messages: 'status-good-register',
          },
        });
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: REGISTER_FAIL,
        });
        if (err.response.status === 409) {
          dispatch({
            type: TOOLTIP_STATE_USER_OPEN,
            payload: {
              status: false,
              messages: 'status-err-register-conflict',
            },
          });
        } else if (err.response.status === (400 || 404)) {
          dispatch({
            type: TOOLTIP_STATE_USER_OPEN,
            payload: {
              status: false,
              messages: 'status-err-register-bad',
            },
          });
        } else {
          dispatch({
            type: TOOLTIP_STATE_USER_OPEN,
            payload: {
              status: false,
              messages: 'status-err-register-all',
            },
          });
        }
      });
  };

export const login = (email: string, password: string) => (dispatch: any) => {
  dispatch({
    type: AUTH_LOADING,
  });
  return AuthService.login(email, password)
    .then((data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: data,
      });
      dispatch({
        type: TOOLTIP_STATE_USER_OPEN,
        payload: {
          status: true,
          messages: 'status-good-login',
        },
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: LOGIN_FAIL,
      });
      if (err.response.status === 401) {
        dispatch({
          type: TOOLTIP_STATE_USER_OPEN,
          payload: {
            status: false,
            messages: 'status-err-login-unauth',
          },
        });
      } else if (err.response.status === (400 || 404)) {
        dispatch({
          type: TOOLTIP_STATE_USER_OPEN,
          payload: {
            status: false,
            messages: 'status-err-login-bad',
          },
        });
      } else {
        dispatch({
          type: TOOLTIP_STATE_USER_OPEN,
          payload: {
            status: false,
            messages: 'status-err-login-all',
          },
        });
      }
    });
};

export const logout = () => (dispatch: any) => {
  dispatch({
    type: AUTH_LOADING,
  });
  return AuthService.logout()
    .then(() => {
      dispatch({
        type: LOGOUT,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: LOGOUT,
      });
    });
};

export const refreshToken = () => (dispatch: any) => {
  dispatch({
    type: AUTH_LOADING,
  });
  return AuthService.refresh()
    .then((data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: data,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: LOGIN_FAIL,
      });
    });
};
