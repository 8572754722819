import React, { FC } from 'react';
import AboutCompany from '../AboutCompany/AboutCompany';
import MainContainer from '../Containers/MainContainer';
import PageContainer from '../Containers/PageContainer';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Promo from '../Promo/Promo';

interface MainProps {
  isMobile: boolean;
  toggleMenu: (e: React.MouseEvent<HTMLElement>) => void;
  handleChangeLanguages: (e: React.MouseEvent<HTMLElement>) => void;
}

const Main: FC<MainProps> = ({ isMobile, toggleMenu, handleChangeLanguages }) => {
  return (
    <PageContainer type="main">
      <Header isMobile={isMobile} toggleMenu={toggleMenu} handleChangeLanguages={handleChangeLanguages} />
      <MainContainer type="main">
        <Promo titleP1="promo-title-p1" titleP2="promo-title-p2" subtitle="promo-subtitle" />
        <AboutCompany title="about-company" />
      </MainContainer>
      <Footer />
    </PageContainer>
  );
};

export default Main;
