class TokenService {
  getLocalAccessToken() {
    const token: string | null = localStorage.getItem('token');
    return token;
  }

  setToken(token: string | any) {
    localStorage.setItem('token', token);
  }

  clear() {
    localStorage.clear();
  }

  getLocalTerms() {
    const terms: string | null = localStorage.getItem('terms');
    return terms;
  }

  setTerms() {
    localStorage.setItem('terms', 'true');
  }
}

export default new TokenService();
