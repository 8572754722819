const contactInfo = {
  title: 'contact-title',
  number_ynp: 'contact-number-ynp',
  location_href: 'https://goo.gl/maps/13HtALmV24DZuEV99',
  location_country: 'contact-location-country',
  location_index: 'contact-location-index',
  location_adress: 'contact-location-adress',
  tel_main: '+375291996099',
  mail_main: 'info@globalsensor.pro',
};
export default contactInfo;
