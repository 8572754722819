import React, { FC } from 'react';
import './ModuleFactsSlider.scss';
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';

import { useTranslation } from 'react-i18next';
import { ModuleFactsType } from '../../@types/module';

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

interface ModuleFactsSliderProps {
  factsList: Array<ModuleFactsType>;
}

const ModuleFactsSlider: FC<ModuleFactsSliderProps> = ({ factsList }) => {
  const { t } = useTranslation();
  return (
    <Swiper
      pagination={{ type: 'progressbar' }}
      navigation={false}
      className="module-facts"
      slidesPerView={3}
      spaceBetween={10}
      breakpoints={{
        '0': {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        '640': {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        '768': {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        '1024': {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      }}
    >
      {factsList.map((i) => (
        <SwiperSlide key={i.id} className="module-facts__slide">
          <article className="module-facts__item">
            <img src={i.image} alt={i.alt} className="module-facts__image" />
            <p className="module-facts__title">{t(i.text)}</p>
          </article>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ModuleFactsSlider;
