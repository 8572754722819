import {
  CHECK_USER,
  FEEDBACK_FORM_LOADING,
  FEEDBACK_FORM_LOADING_ERR,
  FEEDBACK_FORM_SUBMIT_ERR,
  FEEDBACK_FORM_SUBMIT_SUCCESS,
  LOGIN_FAIL,
  TOOLTIP_COOKIES_CLOSE,
  TOOLTIP_COOKIES_OPEN,
  TOOLTIP_STATE_USER_CLOSE,
  TOOLTIP_STATE_USER_OPEN,
  UPDATE_USER_FORM_ERR,
  UPDATE_USER_FORM_LOADING,
  UPDATE_USER_FORM_SUCCESS,
  UPDATE_USER_PASSWORD_FORM_ERR,
  UPDATE_USER_PASSWORD_FORM_LOADING,
  UPDATE_USER_PASSWORD_FORM_SUCCESS,
} from '../types/constants';

import UserService from '../../services/UserService';
import LocalStorageService from '../../services/LocalStorageService';

export const contactFeedback = (fullname: string, email: string, message: string) => (dispatch: any) => {
  dispatch({
    type: FEEDBACK_FORM_LOADING,
  });
  return UserService.feedbackContactForm(fullname, email, message)
    .then(() => {
      dispatch({
        type: FEEDBACK_FORM_SUBMIT_SUCCESS,
      });
      dispatch({
        type: TOOLTIP_STATE_USER_OPEN,
        payload: {
          status: true,
          messages: 'status-good-feedback-form',
        },
      });
    })
    .catch((err) => {
      console.error(err);
      if (err.response.status === (400 || 404)) {
        dispatch({
          type: TOOLTIP_STATE_USER_OPEN,
          payload: {
            status: false,
            messages: 'status-err-feedback-from-bad',
          },
        });
      } else {
        dispatch({
          type: TOOLTIP_STATE_USER_OPEN,
          payload: {
            status: false,
            messages: 'status-err-feedback-from-all',
          },
        });
        dispatch({
          type: FEEDBACK_FORM_SUBMIT_ERR,
        });
      }
    })
    .finally(
      dispatch({
        type: FEEDBACK_FORM_LOADING_ERR,
      }),
    );
};

export const getUserMe = () => (dispatch: any) => {
  return UserService.getUserMe()
    .then((data) => {
      dispatch({
        type: CHECK_USER,
        payload: data,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: LOGIN_FAIL,
        payload: err,
      });
    });
};

export const updateUserMe = (name: string, surname: string) => (dispatch: any) => {
  dispatch({
    type: UPDATE_USER_FORM_LOADING,
  });
  return UserService.updateUser(name, surname)
    .then((data) => {
      dispatch({
        type: UPDATE_USER_FORM_SUCCESS,
        payload: data,
      });
      dispatch({
        type: TOOLTIP_STATE_USER_OPEN,
        payload: {
          status: true,
          messages: 'status-good-update-me',
        },
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: UPDATE_USER_FORM_ERR,
      });
      if (err.response.status === (400 || 404)) {
        dispatch({
          type: TOOLTIP_STATE_USER_OPEN,
          payload: {
            status: false,
            messages: 'status-err-update-me-bad',
          },
        });
      } else {
        dispatch({
          type: TOOLTIP_STATE_USER_OPEN,
          payload: {
            status: false,
            messages: 'status-err-update-me-all',
          },
        });
      }
    });
};

export const updateUserMeAvatar = (avatar: any) => (dispatch: any) => {
  dispatch({
    type: UPDATE_USER_FORM_LOADING,
  });
  return UserService.updateAvatar(avatar)
    .then((data) => {
      dispatch({
        type: UPDATE_USER_FORM_SUCCESS,
        payload: data,
      });
      dispatch({
        type: TOOLTIP_STATE_USER_OPEN,
        payload: {
          status: true,
          messages: 'status-good-update-avatar',
        },
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: UPDATE_USER_FORM_ERR,
      });
      if (err.response.status === 400) {
        dispatch({
          type: TOOLTIP_STATE_USER_OPEN,
          payload: {
            status: false,
            messages: 'status-err-update-avatar-bad',
          },
        });
      } else {
        dispatch({
          type: TOOLTIP_STATE_USER_OPEN,
          payload: {
            status: false,
            messages: 'status-err-update-avatar-all',
          },
        });
      }
    });
};

export const updateUserPassword =
  (oldPassword: string, newPassword: string, verifyPassword: string) => (dispatch: any) => {
    dispatch({
      type: UPDATE_USER_PASSWORD_FORM_LOADING,
    });
    return UserService.updatePassword(oldPassword, newPassword, verifyPassword)
      .then((data) => {
        dispatch({
          type: UPDATE_USER_PASSWORD_FORM_SUCCESS,
          payload: data,
        });
        dispatch({
          type: TOOLTIP_STATE_USER_OPEN,
          payload: {
            status: true,
            messages: 'status-good-update-pas',
          },
        });
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: UPDATE_USER_PASSWORD_FORM_ERR,
        });
        if (err.response.status === 401) {
          dispatch({
            type: TOOLTIP_STATE_USER_OPEN,
            payload: {
              status: false,
              messages: 'status-err-update-pas-unauth',
            },
          });
        } else {
          dispatch({
            type: TOOLTIP_STATE_USER_OPEN,
            payload: {
              status: false,
              messages: 'status-err-update-pas-all',
            },
          });
        }
      });
  };

export const handleTermsUser = () => (dispatch: any) => {
  if (LocalStorageService.getLocalTerms()) {
    dispatch({
      type: TOOLTIP_COOKIES_CLOSE,
    });
  } else {
    dispatch({
      type: TOOLTIP_COOKIES_OPEN,
    });
  }
};

export const handleTermsClose = () => (dispatch: any) => {
  LocalStorageService.setTerms();
  dispatch({
    type: TOOLTIP_COOKIES_CLOSE,
  });
};

export const handleTooltipsPopupUserStateClose = () => (dispatch: any) => {
  dispatch({
    type: TOOLTIP_STATE_USER_CLOSE,
  });
};
