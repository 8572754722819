import React, { FC } from 'react';
import './TooltipStatusForm.scss';
import { useTranslation } from 'react-i18next';
import OkImage from '../../../images/icons/successfull.svg';
import ErrImage from '../../../images/icons/error.svg';

interface TooltipStatusFormProps {
  status: boolean;
  titleFail: string;
  titleGood: string;
  descriptionGood: string;
}

const TooltipStatusForm: FC<TooltipStatusFormProps> = ({ status, titleFail, titleGood, descriptionGood }) => {
  const { t } = useTranslation();
  return (
    <div className="tooltip-form">
      <h2 className="tooltip-form__title">{status ? t(titleFail) : t(titleGood)}</h2>
      <img src={(!status && OkImage) || ErrImage} alt="status" className="tooltip-form__image" />
      {!status && <p className="tooltip-form__subtitle">{t(descriptionGood)}</p>}
    </div>
  );
};

export default TooltipStatusForm;
