import React, { FC } from 'react';
import './ModuleTables.scss';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';
import { ModuleDataParametrsListTypes } from '../../@types/module';
import ModuleTableLight from './ModuleTableLight';

interface ModuleTablesProps {
  data: ModuleDataParametrsListTypes;
}

const ModuleTables: FC<ModuleTablesProps> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Tabs className="module-tabs" selectedTabClassName="module-tabs__list-item_type_active">
      <TabList className="module-tabs__list">
        <Tab className="module-tabs__list-item">{t(data.mainTitle)}</Tab>
        <Tab className="module-tabs__list-item">{t(data.detectorTitle)}</Tab>
      </TabList>

      <TabPanel className="module-tabs__items" selectedClassName="module-tabs__items_type_selected">
        <ModuleTableLight data={data.main} />
      </TabPanel>
      <TabPanel className="module-tabs__items" selectedClassName="module-tabs__items_type_selected">
        <ModuleTableLight data={data.detector} />
      </TabPanel>
    </Tabs>
  );
};

export default ModuleTables;
