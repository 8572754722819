import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UserSubmitFormProfile } from '../../../@types/forms';
import { StoreStateTypes } from '../../../@types/store';
import { validationSchemaProfile } from '../../../utils/validationsForms';
import DropImageInput from '../Inputs/DropImageInput';
import ProfileInput from '../Inputs/ProfileInput';
import OkImage from '../../../images/icons/successfull.svg';
import ErrImage from '../../../images/icons/error.svg';

interface UserUpdateDataFormProps {
  onSubmit: (data: UserSubmitFormProfile) => void;
  isMobile: boolean;
}
const UserUpdateDataForm: FC<UserUpdateDataFormProps> = ({ onSubmit, isMobile }) => {
  const { t } = useTranslation();
  const user = useSelector((state: StoreStateTypes) => state.auth.user);
  const methods = useForm<UserSubmitFormProfile>({
    resolver: yupResolver(validationSchemaProfile),
    mode: 'onBlur',
  });

  return (
    <FormProvider {...methods}>
      <form className="form-profile" onSubmit={methods.handleSubmit(onSubmit)}>
        <h2 className="form-profile__title">{t('profile-settings')}</h2>
        <div className="form-profile__content">
          <DropImageInput
            name="file"
            label="Avatar"
            avatar={user.avatar}
            errors={methods.formState.errors.file?.message}
          />
          <div className="form-profile__box-col">
            <div className="form-profile__box-row">
              <ProfileInput
                label="name"
                name="firstName"
                type="text"
                defValue={user.name}
                errors={methods.formState.errors.firstName?.message}
              />
              <ProfileInput
                label="surname"
                name="lastName"
                type="text"
                defValue={user.surname}
                errors={methods.formState.errors.lastName?.message}
              />
            </div>
            <p className="form-profile__label">{t('email')}</p>
            <div className="form-profile__input form-profile__input_type_email">
              {user.email}
              <span
                className="form-profile__verify"
                style={{ backgroundImage: `url(${user.isActivated ? OkImage : ErrImage})` }}
              >
                {!isMobile && t(user.isActivated ? 'email-verified' : 'email-not-verified')}
              </span>
            </div>
            <button type="submit" className="form-profile__button">
              {t('submit-save')}
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default UserUpdateDataForm;
