import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface TextAreaInputProps {
  label: string;
  name: string;
  error: string | undefined;
}

const TextAreaInput: FC<TextAreaInputProps> = ({ label, name, error }) => {
  const { t } = useTranslation();
  const { register } = useFormContext();
  return (
    <>
      <label className="form__label form__label_theme_white" htmlFor={name}>
        {t(label)}
      </label>
      <textarea {...register(`${name}`)} className={`form__textarea ${error ? 'form__textarea_theme_error' : ''}`} />
      <span className={`form__error ${error && 'form__error_type_visible'}`}>{t(error || '')}</span>
    </>
  );
};

export default TextAreaInput;
