import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './AuthButton.scss';
import accountUserIcon from '../../../images/icons/account-user.svg';

interface AuthButtonProps {
  isMobile: boolean;
  toggleMenu: (e: React.MouseEvent<HTMLElement>) => void;
}

const AuthButton: FC<AuthButtonProps> = ({ isMobile, toggleMenu }) => {
  const { t } = useTranslation();
  return (
    <Link to="/sign-in" className="auth-button">
      <button
        className={`auth-button__signin ${
          isMobile ? 'auth-button__signin_theme_mobile' : 'auth-button__signin_theme_default'
        }`}
        style={{ backgroundImage: `url(${accountUserIcon})` }}
        onClick={isMobile ? toggleMenu : undefined}
        type="button"
        aria-label={t('signin')}
      >
        {t('signin')}
      </button>
    </Link>
  );
};

export default AuthButton;
