import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface DefaultInputProps {
  theme: string;
  label: string;
  name: string;
  error: string | undefined;
  typeInput: string;
}

const DefaultInput: FC<DefaultInputProps> = ({ theme, label, name, error, typeInput }) => {
  const { t } = useTranslation();
  const { register } = useFormContext();
  return (
    <>
      <label className={`form__label form__label_theme_${theme}`} htmlFor={name}>
        {t(label)}
      </label>
      <input
        type={typeInput}
        {...register(`${name}`)}
        className={`form__input form__input_theme_${theme} ${error ? 'form__input_theme_error' : ''}`}
      />
      <span className={`form__error ${error && 'form__error_type_visible'}`}>{t(error || '')}</span>
    </>
  );
};

export default DefaultInput;
