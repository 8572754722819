import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import {
  UserSubmitFormContact,
  UserSubmitFormLogin,
  UserSubmitFormProfile,
  UserSubmitFormRegister,
  UserSubmitFormUpdatePassword,
} from '../../@types/forms';
import { login, logout, refreshToken, register } from '../../store/actions/authActions';
import LocalStorageService from '../../services/LocalStorageService';
import config from '../../utils/config';
import MobileMenu from '../MobileMenu/MobileMenu';
import Main from '../pages/Main';
import { StoreStateTypes } from '../../@types/store';
import Register from '../pages/Register';
import Login from '../pages/Login';
import {
  contactFeedback,
  handleTermsClose,
  handleTermsUser,
  handleTooltipsPopupUserStateClose,
  updateUserMe,
  updateUserMeAvatar,
  updateUserPassword,
} from '../../store/actions/userActions';
import Contact from '../pages/Contact';
import Terms from '../pages/Terms';
import CookiesPopup from '../Popups/CookiesPopup/CookiesPopup';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import Profile from '../pages/Profile';
import PopupTooltipsUserState from '../Popups/PopupTooltipsUserState/PopupTooltipsUserState';
import { moduleRoutes } from '../../utils/moduleData';
import Module from '../pages/Module';
import NotFound from '../pages/NotFound';

const App = () => {
  const state = useSelector((state: StoreStateTypes) => state);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const [windowDimension, setWindowDimension] = useState<number>(window.innerWidth);
  const { i18n } = useTranslation();
  const isMobile: boolean = windowDimension <= config.IS_MOBILE_SIZE;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let timeoutId: any = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setWindowDimension(window.innerWidth);
      }, config.IS_TIMEOUT);
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [isMobile]);

  useEffect(() => {
    if (LocalStorageService.getLocalAccessToken()) {
      dispatch(refreshToken());
    }
    dispatch(handleTermsUser());
  }, [dispatch]);

  const handleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleChangeLanguages = (e: any) => {
    i18n.changeLanguage(e.target.id);
  };

  const handleLogin = (data: UserSubmitFormLogin) => {
    dispatch(login(data.email, data.password));
    navigate('/');
  };

  const handleRegister = (data: UserSubmitFormRegister) => {
    dispatch(register(data.firstName, data.lastName, data.email, data.password, data.acceptTerms));
    navigate('/');
  };

  const handleFeedback = (data: UserSubmitFormContact) => {
    dispatch(contactFeedback(data.fullname, data.email, data.message));
  };

  const handleSubmitUserFormUpdateData = (data: UserSubmitFormProfile) => {
    if (data.file !== undefined) {
      dispatch(updateUserMeAvatar(data.file));
    }
    if (data.firstName !== state.auth.user.name || data.lastName !== state.auth.user.surname) {
      dispatch(updateUserMe(data.firstName, data.lastName));
    }
  };

  const handleSubmitUserFormUpdatePassword = (data: UserSubmitFormUpdatePassword) => {
    dispatch(updateUserPassword(data.oldPassword, data.newPassword, data.verifyPassword));
  };

  const handleUserCookiesAcess = () => {
    dispatch(handleTermsClose());
  };

  const hadnleTooltipPopupStateUser = () => {
    dispatch(handleTooltipsPopupUserStateClose());
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  const handleBackHistory = () => {
    navigate(-1);
  };

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Main isMobile={isMobile} toggleMenu={handleMobileMenu} handleChangeLanguages={handleChangeLanguages} />
          }
        />
        <Route
          path="/sign-up"
          element={<Register handleChangeLanguages={handleChangeLanguages} onSubmit={handleRegister} />}
        />
        <Route
          path="/sign-in"
          element={<Login handleChangeLanguages={handleChangeLanguages} onSubmit={handleLogin} />}
        />
        <Route
          path="/contact"
          element={
            <Contact
              isMobile={isMobile}
              toggleMenu={handleMobileMenu}
              handleChangeLanguages={handleChangeLanguages}
              onSubmit={handleFeedback}
            />
          }
        />
        <Route path="/terms" element={<Terms handleChangeLanguages={handleChangeLanguages} />} />
        <Route
          path="/profile/*"
          element={
            <ProtectedRoute
              children={
                <Profile
                  isMobile={isMobile}
                  onLogout={handleLogout}
                  onUpdateUserData={handleSubmitUserFormUpdateData}
                  onUpdateUserPassword={handleSubmitUserFormUpdatePassword}
                />
              }
            />
          }
        />
        {moduleRoutes.map((route) => (
          <Route
            path={route.path}
            element={
              <Module
                isMobile={isMobile}
                toggleMenu={handleMobileMenu}
                handleChangeLanguages={handleChangeLanguages}
                data={route.data}
              />
            }
            key={route.id}
          />
        ))}

        <Route path="*" element={<NotFound onBack={handleBackHistory} />} />
      </Routes>
      {isMobile && (
        <MobileMenu
          isMobile={isMobile}
          toggleMenu={handleMobileMenu}
          isOpen={isMobileMenuOpen}
          handleChangeLanguages={handleChangeLanguages}
        />
      )}
      {state.userTooltipsService.cookiesTooltipUser.isOpen && <CookiesPopup onSubmit={handleUserCookiesAcess} />}
      {state.userTooltipsService.popupTooltipStates.isOpen && (
        <PopupTooltipsUserState onClose={hadnleTooltipPopupStateUser} />
      )}
    </>
  );
};
function mapStateToProps(state: StoreStateTypes) {
  const { auth, userFormsService, userTooltipsService } = state;
  return {
    auth,
    userFormsService,
    userTooltipsService,
  };
}

export default connect(mapStateToProps)(App);
