import axios from 'axios';
import $api from '../http/index';
import LocalStorageService from './LocalStorageService';

class AuthService {
  async login(email: string, password: string) {
    const response = await $api.post('/auth/signin', {
      email,
      password,
    });
    if (response.data.accessToken) {
      LocalStorageService.setToken(response.data.accessToken);
    }
    return response.data;
  }

  async logout() {
    await $api.post('/auth/logout');
    LocalStorageService.clear();
  }

  async register(name: string, surname: string, email: string, password: string, acceptTerms: boolean) {
    const response = await $api.post('/auth/signup', {
      name,
      surname,
      email,
      password,
      acceptTerms,
    });
    if (response.data.accessToken) {
      LocalStorageService.setToken(response.data.accessToken);
      localStorage.setItem('terms', 'true');
    }
    return response.data.user;
  }

  async refresh() {
    const response = await axios.get('https://api-global-sensor.monster/auth/refresh', {
      withCredentials: true,
    });
    if (response.data.accessToken) {
      LocalStorageService.setToken(response.data.accessToken);
    }
    return response.data;
  }
}

export default new AuthService();
