const links = [
  {
    name: 'Home',
    path: '/',
    id: '1',
  },
  {
    name: 'GS2100',
    path: '/gs2100',
    id: '2',
  },
  {
    name: 'GS2101',
    path: '/gs2100-01',
    id: '3',
  },
  {
    name: 'GS2102',
    path: '/gs2100-02',
    id: '4',
  },
  {
    name: 'GS450',
    path: '/gs450',
    id: '5',
  },
  {
    name: 'GS500',
    path: '/gs500',
    id: '6',
  },
  {
    name: 'Contact',
    path: '/contact',
    id: '7',
  },
];

export default links;
