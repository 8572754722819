import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ModuleDataTableType } from '../../@types/module';

interface ModuleTableLightProps {
  data: Array<ModuleDataTableType>;
}

const ModuleTableLight: FC<ModuleTableLightProps> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <ul className="module-table">
      {data.map((i) => (
        <li className="module-table__item" key={i.id}>
          {i.key && <p className="module-table__key">{t(i.key)}</p>}
          {i.value && <p className="module-table__value">{t(i.value)}</p>}
        </li>
      ))}
    </ul>
  );
};

export default ModuleTableLight;
