import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import './SideBarProfile.scss';
import { useTranslation } from 'react-i18next';
import Logo from '../Icons/Logo/Logo';
import logoutIcon from '../../images/icons/sign-out.svg';
import settingsIcon from '../../images/icons/settings.svg';
import SideBarButton from '../Buttons/SideBarButton/SideBarButton';

interface SideBarProfileProps {
  isOpen: boolean;
  isMobile: boolean;
  toggleSideBar: (e: React.MouseEvent<HTMLElement>) => void;
  onLogout: (e: React.MouseEvent<HTMLElement>) => void;
}

const SideBarProfile: FC<SideBarProfileProps> = ({ isOpen, isMobile, toggleSideBar, onLogout }) => {
  const { t } = useTranslation();
  return (
    <aside className={`profile-bar ${isOpen ? 'profile-bar_type_active' : 'profile-bar_type_hidden'}`}>
      <div
        className={`profile-bar__lens ${isOpen && isMobile && 'profile-bar__lens_type_active'}`}
        onClick={toggleSideBar}
      />
      <div className="profile-bar__items-lens">
        <div className={`profile-bar__items ${isMobile && 'profile-bar__items_type_mobile'}`}>
          <div className="profile-bar__head">
            {isOpen && <Logo type="profile" />}
            <SideBarButton isOpen={isOpen} toggleSideBar={toggleSideBar} />
          </div>
          <div className="profile-bar__content">
            <h2 className={`profile-bar__title ${!isOpen && 'profile-bar__title_type_hidden'}`}>{t('profile-menu')}</h2>
            <nav className="profile-bar__navigation">
              <NavLink
                to="/profile"
                className={({ isActive }) =>
                  isActive ? 'profile-bar__link profile-bar__link_type_active' : 'profile-bar__link'
                }
                end
              >
                <div
                  className="profile-bar__link-icon"
                  style={{ backgroundImage: `url(${settingsIcon})`, backgroundColor: '#9471f6' }}
                />
                <p className={`profile-bar__link-title ${!isOpen && 'profile-bar__link-title_type_hidden'}`}>
                  {t('profile')}
                </p>
              </NavLink>
            </nav>
          </div>
          <div className="profile-bar__bottom">
            <button
              className={`profile-bar__logout ${!isOpen && 'profile-bar__logout_type_hidden'}`}
              onClick={onLogout}
              type="button"
              aria-label={t('logout')}
            >
              <span className={`profile-bar__logout-title ${!isOpen && 'profile-bar__logout-title_type_hidden'}`}>
                {t('logout')}
              </span>
              <span className="profile-bar__logout-icon" style={{ backgroundImage: `url(${logoutIcon})` }} />
            </button>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default SideBarProfile;
