import React, { FC } from 'react';
import './SideBarButton.scss';

interface SideBarButtonProps {
  isOpen: boolean;
  toggleSideBar: (e: React.MouseEvent<HTMLElement>) => void;
}

const SideBarButton: FC<SideBarButtonProps> = ({ isOpen, toggleSideBar }) => {
  return (
    <div
      className={`${isOpen ? 'sidebar-button' : 'sidebar-button sidebar-button_type_open'}`}
      onClick={toggleSideBar}
    />
  );
};

export default SideBarButton;
