import {
  TOOLTIP_COOKIES_CLOSE,
  TOOLTIP_COOKIES_OPEN,
  TOOLTIP_STATE_USER_CLOSE,
  TOOLTIP_STATE_USER_OPEN,
} from '../types/constants';

const initialUserService = {
  cookiesTooltipUser: {
    isOpen: false,
    message: 'cookies-message',
  },
  popupTooltipStates: {
    isOpen: false,
    status: null,
    messages: '',
  },
};

export default function userTooltipsReducer(state = initialUserService, action: any) {
  const { type, payload } = action;

  switch (type) {
    case TOOLTIP_COOKIES_OPEN:
      return {
        ...state,
        cookiesTooltipUser: {
          ...state.cookiesTooltipUser,
          isOpen: true,
        },
      };

    case TOOLTIP_COOKIES_CLOSE:
      return {
        ...state,
        cookiesTooltipUser: {
          ...state.cookiesTooltipUser,
          isOpen: false,
        },
      };
    case TOOLTIP_STATE_USER_OPEN:
      return {
        ...state,
        popupTooltipStates: {
          ...state.popupTooltipStates,
          isOpen: true,
          status: payload.status,
          messages: payload.messages,
        },
      };

    case TOOLTIP_STATE_USER_CLOSE:
      return {
        ...state,
        popupTooltipStates: {
          ...state.popupTooltipStates,
          isOpen: false,
          messages: '',
        },
      };
    default:
      return state;
  }
}
