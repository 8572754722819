import React, { FC } from 'react';
import '../Forms.scss';
import '../Inputs.scss';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from 'react-router-dom';
import { UserSubmitFormRegister } from '../../../@types/forms';
import { validationSchemaRegistration } from '../../../utils/validationsForms';
import DefaultInput from '../Inputs/DefaultInput';
import CheckBoxInput from '../Inputs/CheckBoxInput';

interface RegisterFormProps {
  onSubmit: (data: UserSubmitFormRegister) => void;
  title: string;
}

const RegisterForm: FC<RegisterFormProps> = ({ onSubmit, title }) => {
  const { t } = useTranslation();

  const methods = useForm<UserSubmitFormRegister>({
    resolver: yupResolver(validationSchemaRegistration),
    mode: 'onBlur',
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="form">
        <h1 className="form__title">{t(title)}</h1>
        <DefaultInput
          theme="black"
          label="name"
          typeInput="text"
          name="firstName"
          error={methods.formState.errors.firstName?.message}
        />
        <DefaultInput
          theme="black"
          label="surname"
          typeInput="text"
          name="lastName"
          error={methods.formState.errors.lastName?.message}
        />
        <DefaultInput
          theme="black"
          label="email"
          typeInput="email"
          name="email"
          error={methods.formState.errors.email?.message}
        />
        <DefaultInput
          theme="black"
          label="password"
          typeInput="password"
          name="password"
          error={methods.formState.errors.password?.message}
        />
        <DefaultInput
          theme="black"
          label="confirm-password"
          typeInput="password"
          name="confirmPassword"
          error={methods.formState.errors.confirmPassword?.message}
        />

        <CheckBoxInput
          label="confirm-terms"
          typeInput="checkbox"
          name="acceptTerms"
          error={methods.formState.errors.acceptTerms?.message}
        />

        <button type="submit" className="form__button form__button_type_active" aria-label={t('register')}>
          {t('register')}
        </button>
        <Link to="/sign-in" className="form__link">
          <p className="form__subtitle">
            {t('already_register')}
            <span className="form__span">{t('signin')}</span>
          </p>
        </Link>
      </form>
    </FormProvider>
  );
};

export default RegisterForm;
