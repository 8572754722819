import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import mail from '../../images/icons/mail.svg';
import phone from '../../images/icons/phone.svg';
import location from '../../images/icons/location.svg';
import './ContactInfo.scss';
import { ContactInfoType } from '../../@types/utils';

interface ContactInfoProps {
  contactInfo: ContactInfoType;
}

const ContactInfo: FC<ContactInfoProps> = ({ contactInfo }) => {
  const { t } = useTranslation();
  return (
    <article className="info">
      <div className="info__content info__content_type_head">
        <h2 className="info__title">{t(contactInfo.title)}</h2>
        <p className="info__subtitle">{t(contactInfo.number_ynp)}</p>
      </div>
      <div className="info__content info__content_type_mid">
        <a
          href={contactInfo.location_href}
          style={{ backgroundImage: `url(${location})` }}
          className="info__link-location"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
        </a>
        <div className="info__box-map">
          <p className="info__location">{t(contactInfo.location_country)}</p>
          <p className="info__location">{t(contactInfo.location_index)}</p>
          <p className="info__location">{t(contactInfo.location_adress)}</p>
        </div>
      </div>
      <div className="info__content info__content_type_bottom">
        <div className="info__content_box">
          <img src={phone} alt="tel" className="info__icon" />
          <a href={`tel: ${contactInfo.tel_main}`} className="info__link">
            {contactInfo.tel_main}
          </a>
        </div>
        <div className="info__content_box">
          <img src={mail} alt="mail" className="info__icon" />
          <a className="info__link" href={`mailto:: ${contactInfo.mail_main}`}>
            {contactInfo.mail_main}
          </a>
        </div>
      </div>
    </article>
  );
};

export default ContactInfo;
