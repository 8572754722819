import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import links from '../../utils/links';
import './Navigation.scss';

interface NavigationProps {
  rowLink: boolean;
  isMobile: boolean;
  toggleMenu: (e: React.MouseEvent<HTMLElement>) => void;
}

const Navigation: FC<NavigationProps> = ({ rowLink, isMobile, toggleMenu }) => {
  const { t } = useTranslation();

  const listClassName = `navigation__list ${rowLink ? 'navigation__list_type_row' : 'navigation__list_type_col'}`;
  const listElementClassName = `navigation__list_element ${
    rowLink ? 'navigation__list_element_type_row' : 'navigation__list_element_type_col'
  }`;
  const navLinkClassName = ({ isActive }: any) =>
    isActive
      ? `navigation__link ${
          rowLink
            ? 'navigation__link_type_active-row navigation__link_type_row'
            : ' navigation__link_type_active-col navigation__link_type_col'
        }`
      : `navigation__link ${rowLink ? 'navigation__link_type_row' : 'navigation__link_type_col'}`;
  return (
    <nav className="navigation">
      <ul className={listClassName}>
        {links.map((link) => (
          <li className={listElementClassName} key={link.id}>
            <NavLink to={link.path} className={navLinkClassName} onClick={isMobile ? toggleMenu : undefined}>
              {t(link.name)}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navigation;
