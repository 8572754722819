import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UserSubmitFormContact } from '../../../@types/forms';
import { StoreStateTypes } from '../../../@types/store';
import { validationSchemaContact } from '../../../utils/validationsForms';
import TooltipStatusForm from '../../Popups/TooltipStatusForm/TooltipStatusForm';
import Preloader from '../../Preloader/Preloader';
import DefaultInput from '../Inputs/DefaultInput';
import TextAreaInput from '../Inputs/TextAreaInput';

interface ContactFormProps {
  onSubmit: (data: UserSubmitFormContact) => void;
  title: string;
}

const ContactForm: FC<ContactFormProps> = ({ onSubmit, title }) => {
  const { t } = useTranslation();
  const formState = useSelector((state: StoreStateTypes) => state.userFormsService.feedbackForm);

  const methods = useForm<UserSubmitFormContact>({
    resolver: yupResolver(validationSchemaContact),
    mode: 'onBlur',
  });

  return (
    <FormProvider {...methods}>
      <form className="form form_type_contact" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        {formState.isLoading ? (
          <Preloader />
        ) : formState.isSubmit ? (
          <TooltipStatusForm
            status={formState.isSubmitError}
            titleFail="contact_us_fail"
            titleGood="contact_us_success"
            descriptionGood="contact_us_message"
          />
        ) : (
          <>
            <h2 className="form__title form__title_theme_white">{t(title)}</h2>
            <DefaultInput
              theme="white"
              typeInput="text"
              name="fullname"
              label="fullname"
              error={methods.formState.errors.fullname?.message}
            />
            <DefaultInput
              theme="white"
              typeInput="email"
              name="email"
              label="email"
              error={methods.formState.errors.email?.message}
            />
            <TextAreaInput name="message" label="message" error={methods.formState.errors.message?.message} />
            <button type="submit" className="form__button form__button_type_contact" aria-label={t('submit')}>
              {t('submit')}
            </button>
          </>
        )}
      </form>
    </FormProvider>
  );
};

export default ContactForm;
