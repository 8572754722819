import React, { FC } from 'react';
import './MobileMenuButton.scss';

interface MobileMenuButtonProps {
  toggleMenu: (e: React.MouseEvent<HTMLElement>) => void;
}

const MobileMenuButton: FC<MobileMenuButtonProps> = ({ toggleMenu }) => {
  return (
    <button className="burger-button" onClick={toggleMenu} type="button">
      <span className="burger-button__line" />
    </button>
  );
};

export default MobileMenuButton;
