import React, { FC } from 'react';
import { UserSubmitFormContact } from '../../@types/forms';
import contactInfo from '../../utils/contactInfo';
import ContactInfo from '../ContactInfo/ContactInfo';
import MainContainer from '../Containers/MainContainer';
import PageContainer from '../Containers/PageContainer';
import SectionContainer from '../Containers/SectionContainer';
import Footer from '../Footer/Footer';
import ContactForm from '../Forms/ContactForm/ContactForm';
import Header from '../Header/Header';

interface ContactProps {
  onSubmit: (data: UserSubmitFormContact) => void;
  isMobile: boolean;
  toggleMenu: (e: React.MouseEvent<HTMLElement>) => void;
  handleChangeLanguages: (e: React.MouseEvent<HTMLElement>) => void;
}

const Contact: FC<ContactProps> = ({ onSubmit, isMobile, toggleMenu, handleChangeLanguages }) => {
  return (
    <PageContainer type="contact">
      <Header isMobile={isMobile} toggleMenu={toggleMenu} handleChangeLanguages={handleChangeLanguages} />
      <MainContainer type="contact">
        <SectionContainer type="contact">
          <ContactInfo contactInfo={contactInfo} />
          <ContactForm title="contact_us" onSubmit={onSubmit} />
        </SectionContainer>
      </MainContainer>
      <Footer />
    </PageContainer>
  );
};

export default Contact;
