import $api from '../http/index';

class UserService {
  getUserMe() {
    return $api.get('/user/me').then((response: any) => response.data);
  }

  feedbackContactForm(fullname: string, email: string, message: string) {
    return $api.post('user/contact', { fullname, email, message }).then((response: any) => response.data);
  }

  updateUser(name: string, surname: string) {
    return $api.patch('/user/me', { name, surname }).then((response: any) => response.data);
  }

  updateAvatar(avatar: any) {
    const file = new FormData();
    file.append('file', avatar);

    return $api
      .post('/user/me/avatar', file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response: any) => response.data);
  }

  updatePassword(oldPassword: string, newPassword: string, verifyPassword: string) {
    return $api
      .patch('/user/me/secret', { oldPassword, newPassword, verifyPassword })
      .then((response: any) => response.data);
  }
}

export default new UserService();
