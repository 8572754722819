import React, { FC } from 'react';
import './ProfileUserSettings.scss';
import { useTranslation } from 'react-i18next';
import { UserSubmitFormProfile, UserSubmitFormUpdatePassword } from '../../@types/forms';
import UserUpdateDataForm from '../Forms/UserSettingsForms/UserUpdateDataForm';
import SideBarMobileButton from '../Buttons/SideBarButton/SideBarMobileButton';
import UserUpdatePasswordForm from '../Forms/UserSettingsForms/UserUpdatePasswordForm';

interface ProfileUserSettingsProps {
  onSubmitUpdateProfileData: (data: UserSubmitFormProfile) => void;
  onSubmitUpdatePassword: (data: UserSubmitFormUpdatePassword) => void;
  toggleSideBar: (e: React.MouseEvent<HTMLElement>) => void;
  isMobile: boolean;
}

const ProfileUserSettings: FC<ProfileUserSettingsProps> = ({
  onSubmitUpdateProfileData,
  onSubmitUpdatePassword,
  isMobile,
  toggleSideBar,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="profile-preview">
        <h1 className="profile-preview__title">{t('profile-personal-info')}</h1>
        {isMobile && <SideBarMobileButton toggleSideBar={toggleSideBar} />}
      </div>
      <UserUpdateDataForm onSubmit={onSubmitUpdateProfileData} isMobile={isMobile} />
      <UserUpdatePasswordForm onSubmit={onSubmitUpdatePassword} />
    </>
  );
};

export default ProfileUserSettings;
