import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface ProfileInputProps {
  name: string;
  label: string;
  defValue?: string;
  errors?: string;
  type: string;
}

const ProfileInput: FC<ProfileInputProps> = ({ name, label, defValue, errors, type }) => {
  const { t } = useTranslation();
  const { register } = useFormContext();

  return (
    <label className="form-profile__label" htmlFor={name}>
      {t(label)}
      <input
        type={type}
        {...register(`${name}`)}
        defaultValue={`${defValue}`}
        className={`form-profile__input ${errors ? 'form-profile__input_theme_error' : ''}`}
      />
      <span className={`form__error ${errors && 'form__error_type_visible'}`}>{t(errors || '')}</span>
    </label>
  );
};

export default ProfileInput;
