import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '../Icons/Logo/Logo';
import './NotFoundErr.scss';

interface NotFoundErrProps {
  onBack: (e: React.MouseEvent<HTMLElement>) => void;
}

const NotFounErr: FC<NotFoundErrProps> = ({ onBack }) => {
  const { t } = useTranslation();
  return (
    <div className="not-found">
      <div className="not-found__logo">
        <Logo />
      </div>
      <h1 className="not-found__title">404</h1>
      <p className="not-found__subtitle">{t('not-found-title')}</p>
      <button className="not-found__button" type="button" onClick={onBack}>
        {t('back-button')}
      </button>
    </div>
  );
};

export default NotFounErr;
