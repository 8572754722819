import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { UserSubmitFormLogin } from '../../../@types/forms';
import { validationSchemaLogin } from '../../../utils/validationsForms';
import DefaultInput from '../Inputs/DefaultInput';

interface LoginFormProps {
  onSubmit: (data: UserSubmitFormLogin) => void;
  title: string;
}

const LoginForm: FC<LoginFormProps> = ({ onSubmit, title }) => {
  const { t } = useTranslation();

  const methods = useForm<UserSubmitFormLogin>({
    resolver: yupResolver(validationSchemaLogin),
    mode: 'onBlur',
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="form">
        <h1 className="form__title">{t(title)}</h1>
        <DefaultInput
          theme="black"
          label="email"
          typeInput="email"
          name="email"
          error={methods.formState.errors.email?.message}
        />
        <DefaultInput
          theme="black"
          label="password"
          typeInput="password"
          name="password"
          error={methods.formState.errors.password?.message}
        />

        <button type="submit" className="form__button form__button_type_active form__button_type_login">
          {t('signin')}
        </button>
        <Link to="/sign-up" className="form__link">
          <p className="form__subtitle">
            {t('not_register')}
            <span className="form__span">{t('registration-title')}</span>
          </p>
        </Link>
      </form>
    </FormProvider>
  );
};

export default LoginForm;
