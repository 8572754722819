import React, { FC } from 'react';
import './CrossButton.scss';

interface CrossButtonProps {
  onClose: (e: React.MouseEvent<HTMLElement>) => void;
}

const CrossButton: FC<CrossButtonProps> = ({ onClose }) => {
  return <span className="close-button" onClick={onClose} />;
};

export default CrossButton;
