import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface CheckBoxInputProps {
  label: string;
  name: string;
  error: string | undefined;
  typeInput: string;
}

const CheckBoxInput: FC<CheckBoxInputProps> = ({ label, name, error, typeInput }) => {
  const { t } = useTranslation();
  const { register } = useFormContext();
  return (
    <>
      <div className="form__check">
        <input
          type={typeInput}
          {...register(`${name}`)}
          className={`form-check-input ${error ? 'form__input_theme_error' : ''}`}
        />
        <label className="form__label form__label_type_terms" htmlFor={name}>
          {t(label)}
          <Link to="/terms" className="form-terms__link">
            <p className="form__terms">{t('form-terms')}</p>
          </Link>
        </label>
      </div>
      <span className={`form__error ${error && 'form__error_type_visible'}`}>{t(error || '')}</span>
    </>
  );
};

export default CheckBoxInput;
