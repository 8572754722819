import React, { FC, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { UserSubmitFormProfile, UserSubmitFormUpdatePassword } from '../../@types/forms';
import MainContainer from '../Containers/MainContainer';
import PageContainer from '../Containers/PageContainer';
import SectionContainer from '../Containers/SectionContainer';
import ProfileUserSettings from '../ProfileUserSettings/ProfileUserSettings';
import SideBarProfile from '../SideBarProfile/SideBarProfile';

interface ProfileProps {
  isMobile: boolean;
  onLogout: (e: React.MouseEvent<HTMLElement>) => void;
  onUpdateUserData: (data: UserSubmitFormProfile) => void;
  onUpdateUserPassword: (data: UserSubmitFormUpdatePassword) => void;
}

const Profile: FC<ProfileProps> = ({ isMobile, onLogout, onUpdateUserData, onUpdateUserPassword }) => {
  const [isOpenSideBar, setIsOpenSideBar] = useState(true);

  const handleSideBarOpen = () => {
    setIsOpenSideBar(!isOpenSideBar);
  };

  useEffect(() => {
    if (isMobile) {
      setIsOpenSideBar(false);
    }
  }, [isMobile]);

  return (
    <PageContainer type="profile">
      <SideBarProfile
        isOpen={isOpenSideBar}
        toggleSideBar={handleSideBarOpen}
        onLogout={onLogout}
        isMobile={isMobile}
      />
      <MainContainer type="profile">
        <SectionContainer type="profile">
          <Routes>
            <Route
              path="/"
              element={
                <ProfileUserSettings
                  onSubmitUpdateProfileData={onUpdateUserData}
                  onSubmitUpdatePassword={onUpdateUserPassword}
                  toggleSideBar={handleSideBarOpen}
                  isMobile={isMobile}
                />
              }
            />
          </Routes>
        </SectionContainer>
      </MainContainer>
    </PageContainer>
  );
};

export default Profile;
