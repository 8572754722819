import axios, { AxiosRequestConfig } from 'axios';
import LocalStorageService from '../services/LocalStorageService';

export const API_URL = 'https://api-global-sensor.monster';
const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

$api.interceptors.request.use((config: AxiosRequestConfig | any) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

$api.interceptors.response.use(
  (config) => config,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.response.status === 409 && error.config && !error.config._isRetry) {
      originalRequest._isRetry = true;
      try {
        const response = await axios.get(`${API_URL}/auth/refresh`, {
          withCredentials: true,
        });
        LocalStorageService.setToken(response.data.accessToken);
        return await $api.request(originalRequest);
      } catch (e) {
        console.log('Not Auth!');
      }
    }
    throw error;
  },
);

export default $api;
