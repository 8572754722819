import React, { FC, ReactNode } from 'react';
import './Containers.scss';

interface MainContainerProps {
  children: ReactNode;
  type: string;
}

const MainContainer: FC<MainContainerProps> = ({ children, type }) => {
  return <main className={`main main_type_${type}`}>{children}</main>;
};
export default MainContainer;
