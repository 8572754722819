import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface DropImageInputProps {
  avatar: string;
  name: string;
  label: string;
  errors?: string;
}

const DropImageInput: FC<DropImageInputProps> = ({ avatar, name, label, errors }) => {
  const { t } = useTranslation();
  const [isAvatar, setisAvatar] = useState(avatar);
  const { register, unregister, setValue } = useFormContext();

  const onDrop = useCallback(
    (droppedFiles) => {
      setValue(name, droppedFiles[0], { shouldValidate: true });
      setisAvatar(droppedFiles.map((file: any) => URL.createObjectURL(file)));
    },
    [setValue, name],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  useEffect(() => {
    register(name);

    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);
  return (
    <div className="form__box-drop">
      <div
        {...getRootProps()}
        role="button"
        aria-label="File Upload"
        id={name}
        className="form__drop"
        style={{ backgroundImage: `url(${isAvatar})` }}
      >
        <input name={name} {...getInputProps()} type="file" className="form-profile__input-drop" />

        {isDragActive ? (
          <p className="form__drop-title">{t('image-drop')}</p>
        ) : (
          <p className="form__drop-title">{t('image-drag')}</p>
        )}
      </div>
      <span className={`form__error form__error-avatar ${errors && 'form__error_type_visible'}`}>
        {t(errors || '')}
      </span>
    </div>
  );
};

export default DropImageInput;
