import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StoreStateTypes } from '../../../@types/store';
import './CookiesPopup.scss';

interface CookiesPopupProps {
  onSubmit: (e: React.MouseEvent<HTMLElement>) => void;
}

const CookiesPopup: FC<CookiesPopupProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const tooltip = useSelector((state: StoreStateTypes) => state.userTooltipsService.cookiesTooltipUser);
  return (
    <div className={`popup-cookies ${tooltip.isOpen ? 'popup-cookies_type_visible' : 'popup-cookies_type_hidden'} `}>
      <p className="popup-cookies__title">{t(tooltip.message)}</p>
      <button className="popup-cookies__button" type="button" aria-label="ok" onClick={onSubmit}>
        OK
      </button>
    </div>
  );
};

export default CookiesPopup;
