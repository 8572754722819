import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { availableLanguages } from '../../i18n/i18n';
import AccountBar from '../AccountBar/AccountBar';
import CrossButton from '../Buttons/CrossButton/CrossButton';
import SwitchLanguagesButton from '../Buttons/SwitchLanguagesButton/SwitchLanguagesButton';
import Navigation from '../Navigation/Navigation';
import './MobileMenu.scss';

interface MobileMenuProps {
  toggleMenu: (e: React.MouseEvent<HTMLElement>) => void;
  handleChangeLanguages: (e: React.MouseEvent<HTMLElement>) => void;
  isOpen: boolean;
  isMobile: boolean;
}

const MobileMenu: FC<MobileMenuProps> = ({ isOpen, isMobile, toggleMenu, handleChangeLanguages }) => {
  const { i18n } = useTranslation();
  return (
    <aside className={`menu ${isOpen && isMobile ? 'menu_type_visible' : 'menu_type_hidden'}`}>
      <div className="menu__lens" onDoubleClick={toggleMenu} />
      <div className="menu__lens-content">
        <div className="menu__content">
          <div className="menu__content-head">
            <SwitchLanguagesButton
              i18n={i18n}
              availableLanguages={availableLanguages}
              type="black"
              handleChangeLanguages={handleChangeLanguages}
            />
            <CrossButton onClose={toggleMenu} />
          </div>
          <div className="menu__content-middle">
            <Navigation rowLink={false} isMobile={isMobile} toggleMenu={toggleMenu} />
          </div>

          <div className="menu__content-bottom">
            <AccountBar isMobile={isMobile} toggleMenu={toggleMenu} locationMenu />
          </div>
        </div>
      </div>
    </aside>
  );
};

export default MobileMenu;
