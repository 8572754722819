import React, { FC } from 'react';
import './TermsList.scss';
import { useTranslation } from 'react-i18next';
import { TermsType, TermsTypeSubtitle, TermsTypeParagraf } from '../../@types/utils';
import Logo from '../Icons/Logo/Logo';
import SwitchLanguagesButton from '../Buttons/SwitchLanguagesButton/SwitchLanguagesButton';
import { availableLanguages } from '../../i18n/i18n';

interface TermsListProps {
  terms: Array<TermsType>;
  handleChangeLanguages: (e: React.MouseEvent<HTMLElement>) => void;
}

const TermsList: FC<TermsListProps> = ({ terms, handleChangeLanguages }) => {
  const { i18n, t } = useTranslation();
  return (
    <>
      <div className="terms-preview">
        <div className="terms-preview__head">
          <Logo />
          <SwitchLanguagesButton
            i18n={i18n}
            availableLanguages={availableLanguages}
            type="black"
            handleChangeLanguages={handleChangeLanguages}
          />
        </div>
        <div className="terms-preview__list">
          <h1 className="terms-preview__title">{t('terms-title-1')}</h1>
          <p className="terms-preview__subtitle">{t('terms-1-subtitle-1')}</p>
          <p className="terms-preview__subtitle">{t('terms-1-subtitle-2')}</p>
          <p className="terms-preview__subtitle">{t('terms-1-subtitle-3')}</p>
          <p className="terms-preview__subtitle">{t('terms-1-subtitle-4')}</p>
        </div>
      </div>
      <ol className="terms">
        {terms.map((item) => (
          <ol key={item.title} className="terms__list">
            <h2 className="terms__title">{t(item.title)}</h2>
            {item.subtitles.map((element: TermsTypeSubtitle) => (
              <li key={element.text} className="terms__list-element">
                <p className="terms__subtitle">{t(element.text)}</p>
                {element.paragrafs && element.paragrafs.length > 0 && (
                  <ul className="terms__paragrafs">
                    {element.paragrafs.map((p: TermsTypeParagraf) => (
                      <li className="terms__paragraf" key={p.paragraf}>
                        <p className="terms__paragraf-title">{t(p.paragraf)}</p>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ol>
        ))}
      </ol>
    </>
  );
};

export default TermsList;
