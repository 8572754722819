import React, { FC } from 'react';
import { ModuleTypesData } from '../../@types/module';
import MainContainer from '../Containers/MainContainer';
import PageContainer from '../Containers/PageContainer';
import SectionContainer from '../Containers/SectionContainer';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import ModuleDescriptions from '../ModuleDescriptions/ModuleDescriptions';
import ModuleEquipments from '../ModuleEquipments/ModuleEquipments';
import ModuleFactsSlider from '../ModuleFactsSlider/ModuleFactsSlider';
import ModuleSlider from '../ModuleSlider/ModuleSlider';
import ModuleTables from '../ModuleTables/ModuleTables';

interface ModuleProps {
  isMobile: boolean;
  toggleMenu: (e: React.MouseEvent<HTMLElement>) => void;
  handleChangeLanguages: (e: React.MouseEvent<HTMLElement>) => void;
  data: ModuleTypesData;
}

const Module: FC<ModuleProps> = ({ data, isMobile, toggleMenu, handleChangeLanguages }) => {
  return (
    <PageContainer type="module">
      <Header isMobile={isMobile} toggleMenu={toggleMenu} handleChangeLanguages={handleChangeLanguages} />
      <MainContainer type="module">
        <SectionContainer type="module-promo">
          <ModuleSlider imageList={data.images} />
          <ModuleDescriptions descriptionsList={data.descriptions} title={data.name} />
        </SectionContainer>
        <SectionContainer type="module-tables">
          <ModuleEquipments data={data.equipments} />
        </SectionContainer>
        <SectionContainer type="module-tables">
          <ModuleTables data={data.dataModule} />
        </SectionContainer>
        <SectionContainer type="module-facts">
          <ModuleFactsSlider factsList={data.facts} />
        </SectionContainer>
      </MainContainer>
      <Footer />
    </PageContainer>
  );
};

export default Module;
