import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UserSubmitFormUpdatePassword } from '../../../@types/forms';
import { validationSchemaUpdatePassword } from '../../../utils/validationsForms';
import ProfileInput from '../Inputs/ProfileInput';

interface UserUpdatePasswordFormProps {
  onSubmit: (data: UserSubmitFormUpdatePassword) => void;
}

const UserUpdatePasswordForm: FC<UserUpdatePasswordFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const methods = useForm<UserSubmitFormUpdatePassword>({
    resolver: yupResolver(validationSchemaUpdatePassword),
    mode: 'onBlur',
  });
  return (
    <FormProvider {...methods}>
      <form className="form-profile form-profile_theme_center" onSubmit={methods.handleSubmit(onSubmit)}>
        <h2 className="form-profile__title">{t('profile-personal-password-update')}</h2>
        <div className="form-profile___content form-profile__content_type_password">
          <ProfileInput
            label="old-password"
            type="password"
            defValue=""
            name="oldPassword"
            errors={methods.formState.errors.oldPassword?.message}
          />
          <ProfileInput
            label="new-password"
            type="password"
            name="newPassword"
            defValue=""
            errors={methods.formState.errors.newPassword?.message}
          />
          <ProfileInput
            label="confirm-password"
            name="verifyPassword"
            type="password"
            defValue=""
            errors={methods.formState.errors.verifyPassword?.message}
          />
        </div>

        <button
          type="submit"
          className={`form-profile__button  form-profile__button_theme_center ${
            !methods.formState.isValid && 'form-profile__button_type_disabled'
          }`}
          disabled={!methods.formState.isValid}
        >
          {t('submit-save')}
        </button>
      </form>
    </FormProvider>
  );
};

export default UserUpdatePasswordForm;
