import React, { FC } from 'react';

interface SideBarMobileButtonProps {
  toggleSideBar: (e: React.MouseEvent<HTMLElement>) => void;
}

const SideBarMobileButton: FC<SideBarMobileButtonProps> = ({ toggleSideBar }) => {
  return (
    <div className="sidebar-button  sidebar-button_type_open sidebar-button_theme_white" onClick={toggleSideBar} />
  );
};

export default SideBarMobileButton;
