export const moduleRoutes = [
  {
    path: '/gs2100',
    data: {
      images: [{ image: `${`${process.env.PUBLIC_URL}/module/main.png`}`, alt: '', id: 1 }],
      name: 'gs2100',
      descriptions: [
        { text: 'gs2100-promo-p1', id: 1 },
        { text: 'gs2100-promo-p2', id: 2 },
        { text: 'gs2100-promo-p3', id: 3 },
      ],
      dataModule: {
        mainTitle: 'module-parametr',
        main: [
          { key: 'dimensions', value: 'pm2100-dimensions', id: 1 },
          { key: 'weight', value: 'pm2100-weight', id: 2 },
          { key: 'power', value: 'pm2100-power', id: 3 },
          { key: 'interface', value: 'pm2100-interface', id: 4 },
        ],
        detectorTitle: 'detector-parametr',
        detector: [
          { key: 'detector-description', value: '', id: 1 },
          { key: 'detector', value: 'detector-ti', id: 2 },
          { key: 'detector-avarage', value: 'detector-range', id: 3 },
          { key: 'detector-renge-energe', value: 'detector-energe', id: 4 },
          { key: 'detector-min-sub', value: '', id: 5 },
          { key: 'detector-min-sub-gamma', value: 'detector-min-sub-for', id: 6 },
        ],
      },
      facts: [
        {
          text: 'gs-facts-1',
          image: `${`${process.env.PUBLIC_URL}/assets/icons/world.svg`}`,
          alt: 'gs-facts-1',
          id: 1,
        },
        {
          text: 'gs-facts-2',
          image: `${`${process.env.PUBLIC_URL}/assets/icons/bio.svg`}`,
          alt: 'gs-facts-2',
          id: 2,
        },
        {
          text: 'gs-facts-3',
          image: `${`${process.env.PUBLIC_URL}/assets/icons/clack.svg`}`,
          alt: 'gs-facts-3',
          id: 3,
        },
      ],
      equipments: ['module-includes-p1', 'module-includes-p2', 'module-includes-p3'],
    },
    id: 1,
  },
  {
    path: '/gs2100-01',
    data: {
      images: [{ image: `${`${process.env.PUBLIC_URL}/module/2101.png`}`, alt: '', id: 1 }],
      name: 'gs2101',
      descriptions: [
        { text: 'gs2101-promo-p1', id: 1 },
        { text: 'gs2101-promo-p2', id: 2 },
        { text: 'gs2101-promo-p3', id: 3 },
      ],
      dataModule: {
        mainTitle: 'module-parametr',
        main: [
          { key: 'dimensions', value: 'pm2101-dimensions', id: 1 },
          { key: 'weight', value: 'pm2101-weight', id: 2 },
          { key: 'power', value: 'pm2101-power', id: 3 },
          { key: 'interface', value: 'pm2101-interface', id: 4 },
        ],
        detectorTitle: 'detector-parametr',
        detector: [
          { key: 'detector-description', value: '', id: 1 },
          { key: 'detector', value: 'detector-ti', id: 2 },
          { key: 'detector-avarage', value: 'detector-range', id: 3 },
          { key: 'detector-renge-energe', value: 'detector-energe', id: 4 },
          { key: 'detector-min-sub', value: '', id: 5 },
          { key: 'detector-min-sub-gamma', value: 'detector-min-sub-for', id: 6 },
        ],
      },
      facts: [
        {
          text: 'gs-facts-1',
          image: `${`${process.env.PUBLIC_URL}/assets/icons/world.svg`}`,
          alt: 'gs-facts-1',
          id: 1,
        },
        {
          text: 'gs-facts-2',
          image: `${`${process.env.PUBLIC_URL}/assets/icons/bio.svg`}`,
          alt: 'gs-facts-2',
          id: 2,
        },
        {
          text: 'gs-facts-3',
          image: `${`${process.env.PUBLIC_URL}/assets/icons/clack.svg`}`,
          alt: 'gs-facts-3',
          id: 3,
        },
      ],
      equipments: [
        'module-includes-gs2101-p1',
        'module-includes-gs2101-p4',
        'module-includes-p2',
        'module-includes-p3',
      ],
    },
    id: 2,
  },
  {
    path: '/gs2100-02',
    data: {
      images: [{ image: `${`${process.env.PUBLIC_URL}/module/2102.png`}`, alt: '', id: 1 }],
      name: 'gs2102',
      descriptions: [
        { text: 'gs2102-promo-p1', id: 1 },
        { text: 'gs2102-promo-p2', id: 2 },
        { text: 'gs2102-promo-p3', id: 3 },
      ],
      dataModule: {
        mainTitle: 'module-parametr',
        main: [
          { key: 'dimensions', value: 'pm2102-dimensions', id: 1 },
          { key: 'weight', value: 'pm2102-weight', id: 2 },
          { key: 'power', value: 'pm2102-power', id: 3 },
          { key: 'transmission', value: 'pm2102-interface', id: 4 },
        ],
        detectorTitle: 'detector-parametr',
        detector: [
          { key: 'detector-description', value: '', id: 1 },
          { key: 'detector', value: 'detector-ti', id: 2 },
          { key: 'detector-avarage', value: 'detector-range', id: 3 },
          { key: 'detector-renge-energe', value: 'detector-energe', id: 4 },
          { key: 'detector-min-sub', value: '', id: 5 },
          { key: 'detector-min-sub-gamma', value: 'detector-min-sub-for', id: 6 },
        ],
      },
      facts: [
        {
          text: 'gs-facts-1',
          image: `${`${process.env.PUBLIC_URL}/assets/icons/world.svg`}`,
          alt: 'gs-facts-1',
          id: 1,
        },
        {
          text: 'gs-facts-2',
          image: `${`${process.env.PUBLIC_URL}/assets/icons/bio.svg`}`,
          alt: 'gs-facts-2',
          id: 2,
        },
        {
          text: 'gs-facts-3',
          image: `${`${process.env.PUBLIC_URL}/assets/icons/clack.svg`}`,
          alt: 'gs-facts-3',
          id: 3,
        },
      ],
      equipments: ['module-includes-p1', 'module-includes-p2', 'module-includes-p3'],
    },
    id: 3,
  },
  {
    path: '/gs450',
    data: {
      images: [{ image: `${`${process.env.PUBLIC_URL}/module/gs450.png`}`, alt: '', id: 1 }],
      name: 'gs450',
      descriptions: [
        { text: 'gs450-promo-p1', id: 1 },
        { text: 'gs450-promo-p2', id: 2 },
        { text: 'gs450-promo-p3', id: 3 },
      ],
      dataModule: {
        mainTitle: 'module-parametr',
        main: [
          { key: 'dimensions', value: 'pm2102-dimensions', id: 1 },
          { key: 'weight', value: 'pm2102-weight', id: 2 },
          { key: 'power', value: 'pm2102-power', id: 3 },
          { key: 'interface', value: 'pm2102-interface', id: 4 },
        ],
        detectorTitle: 'detector-parametr',
        detector: [
          { key: 'detector-description', value: '', id: 1 },
          { key: 'detector', value: 'detector-ti', id: 2 },
          { key: 'detector-avarage', value: 'detector-range', id: 3 },
          { key: 'detector-renge-energe', value: 'detector-energe', id: 4 },
          { key: 'detector-min-sub', value: '', id: 5 },
          { key: 'detector-min-sub-gamma', value: 'detector-min-sub-for', id: 6 },
        ],
      },
      facts: [
        {
          text: 'gs-facts-1',
          image: `${`${process.env.PUBLIC_URL}/assets/icons/world.svg`}`,
          alt: 'gs-facts-1',
          id: 1,
        },
        {
          text: 'gs-facts-2',
          image: `${`${process.env.PUBLIC_URL}/assets/icons/bio.svg`}`,
          alt: 'gs-facts-2',
          id: 2,
        },
        {
          text: 'gs-facts-3',
          image: `${`${process.env.PUBLIC_URL}/assets/icons/clack.svg`}`,
          alt: 'gs-facts-3',
          id: 3,
        },
      ],
      equipments: ['module-includes-gs450-p1', 'module-includes-gs450-p2', 'module-includes-gs450-p3'],
    },
    id: 4,
  },
  {
    path: '/gs500',
    data: {
      images: [{ image: `${`${process.env.PUBLIC_URL}/module/gs500.png`}`, alt: '', id: 1 }],
      name: 'gs500',
      descriptions: [
        { text: 'gs500-promo-p1', id: 1 },
        { text: 'gs500-promo-p2', id: 2 },
        { text: 'gs500-promo-p3', id: 3 },
      ],
      dataModule: {
        mainTitle: 'module-parametr',
        main: [
          { key: 'dimensions', value: 'pm2102-dimensions', id: 1 },
          { key: 'weight', value: 'pm2102-weight', id: 2 },
          { key: 'power', value: 'pm2102-power', id: 3 },
          { key: 'interface', value: 'pm2102-interface', id: 4 },
        ],
        detectorTitle: 'detector-parametr',
        detector: [
          { key: 'detector-description', value: '', id: 1 },
          { key: 'detector', value: 'detector-ti', id: 2 },
          { key: 'detector-avarage', value: 'detector-range', id: 3 },
          { key: 'detector-renge-energe', value: 'detector-energe', id: 4 },
          { key: 'detector-min-sub', value: '', id: 5 },
          { key: 'detector-min-sub-gamma', value: 'detector-min-sub-for', id: 6 },
        ],
      },
      facts: [
        {
          text: 'gs-facts-1',
          image: `${`${process.env.PUBLIC_URL}/assets/icons/world.svg`}`,
          alt: 'gs-facts-1',
          id: 1,
        },
        {
          text: 'gs-facts-2',
          image: `${`${process.env.PUBLIC_URL}/assets/icons/bio.svg`}`,
          alt: 'gs-facts-2',
          id: 2,
        },
        {
          text: 'gs-facts-3',
          image: `${`${process.env.PUBLIC_URL}/assets/icons/clack.svg`}`,
          alt: 'gs-facts-3',
          id: 3,
        },
      ],
      equipments: ['module-axob-includes-p1', 'module-axob-includes-p2', 'module-axob-includes-p3'],
    },
    id: 4,
  },
];
