import React, { FC } from 'react';
import './ModuleSlider.scss';
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Lazy, Pagination, Navigation } from 'swiper';

// eslint-disable-next-line import/no-relative-packages
import '../../../node_modules/swiper/swiper.scss'; // core Swiper
// eslint-disable-next-line import/no-relative-packages
import '../../../node_modules/swiper/modules/lazy/lazy.scss';
// eslint-disable-next-line import/no-relative-packages
import '../../../node_modules/swiper/modules/navigation/navigation.scss'; // Navigation module
// eslint-disable-next-line import/no-relative-packages
import '../../../node_modules/swiper/modules/pagination/pagination.scss';

import { ModuleImagesListTypes } from '../../@types/module';

SwiperCore.use([Lazy, Pagination, Navigation]);

interface ModuleSliderProps {
  imageList: Array<ModuleImagesListTypes>;
}

const ModuleSlider: FC<ModuleSliderProps> = ({ imageList }) => {
  return (
    <Swiper lazy pagination={{ clickable: true }} navigation={false} className="module-slider">
      {imageList.map((item) => (
        <SwiperSlide key={item.id}>
          <img alt={item.alt} data-src={item.image} src={item.image} className="swiper-lazy module-slider__slide" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ModuleSlider;
