import React, { FC, ReactNode } from 'react';

interface SectionContainerProps {
  children: ReactNode;
  type: string;
}

const SectionContainer: FC<SectionContainerProps> = ({ children, type }) => {
  return (
    <section className={`section section_type_${type}`}>
      <div className={`section__content section__content_type_${type}`}>{children}</div>
    </section>
  );
};

export default SectionContainer;
