import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CHECK_USER,
  AUTH_LOADING,
  UPDATE_USER_FORM_SUCCESS,
} from '../types/constants';

const initialState = {
  isLoading: null,
  isLoggedIn: false,
  user: null,
};

export default function authReducer(state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case AUTH_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
        user: payload,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
        user: payload.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        user: null,
      };
    case CHECK_USER:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
        user: { ...state, ...payload },
      };
    case UPDATE_USER_FORM_SUCCESS:
      return {
        ...state,
        user: { ...payload },
      };
    default:
      return state;
  }
}
