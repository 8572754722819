import React, { FC } from 'react';
import './Promo.scss';
import { useTranslation } from 'react-i18next';
import Particles from 'react-tsparticles';
import SectionContainer from '../Containers/SectionContainer';

interface PromoProps {
  titleP1: string;
  titleP2: string;
  subtitle: string;
}

const Promo: FC<PromoProps> = ({ titleP1, titleP2, subtitle }) => {
  const { t } = useTranslation();
  return (
    <SectionContainer type="promo">
      <h1 className="promo__title">
        {t(titleP1)}
        <br />
        {t(titleP2)}
      </h1>
      <p className="promo__subtitle">{t(subtitle)}</p>
      <Particles
        id="tsparticles"
        className="tsparticles"
        canvasClassName="tsparticles-canvas"
        options={{
          fpsLimit: 60,
          style: {
            position: 'absolute',
          },
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: 'push',
              },
              onHover: {
                enable: true,
                mode: 'repulse',
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 2,
                opacity: 0.8,
                size: 40,
              },
              push: {
                quantity: 2,
              },
              repulse: {
                distance: 80,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: '#ffffff',
            },
            links: {
              color: '#ffffff',
              distance: 200,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: 'none',
              enable: true,
              outMode: 'bounce',
              random: false,
              speed: 1,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                value_area: 800,
              },
              value: 40,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: 'circle',
            },
            size: {
              random: true,
              value: 5,
            },
          },
          detectRetina: true,
        }}
      />
    </SectionContainer>
  );
};

export default Promo;
