import React, { FC } from 'react';
import './PopupTooltipsUserState.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { StoreStateTypes } from '../../../@types/store';
import OkImage from '../../../images/icons/successfull.svg';
import ErrImage from '../../../images/icons/error.svg';

interface PopupTooltipsUserStateProps {
  onClose: (e: React.MouseEvent<HTMLElement>) => void;
}

const PopupTooltipsUserState: FC<PopupTooltipsUserStateProps> = ({ onClose }) => {
  const tooltipData = useSelector((state: StoreStateTypes) => state.userTooltipsService.popupTooltipStates);
  const { t } = useTranslation();
  return (
    <div
      className={`popup-state ${tooltipData.isOpen ? 'popup-state_type_visible' : 'popup-state_type_hidden'}`}
      onClick={onClose}
    >
      <img
        src={tooltipData.status ? OkImage : ErrImage}
        alt={tooltipData.status ? 'good' : 'err'}
        className="popup-state__image"
      />
      <p className="popup-state__messages">{t(tooltipData.messages)}</p>
      <button className="popup-state__button" type="button" aria-label="close" onClick={onClose} />
    </div>
  );
};

export default PopupTooltipsUserState;
