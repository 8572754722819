import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UserSubmitFormRegister } from '../../@types/forms';
import { availableLanguages } from '../../i18n/i18n';
import SwitchLanguagesButton from '../Buttons/SwitchLanguagesButton/SwitchLanguagesButton';
import MainContainer from '../Containers/MainContainer';
import SectionContainer from '../Containers/SectionContainer';
import RegisterForm from '../Forms/AuthForms/RegisterForm';
import Logo from '../Icons/Logo/Logo';

interface RegisterProps {
  onSubmit: (data: UserSubmitFormRegister) => void;
  handleChangeLanguages: (e: React.MouseEvent<HTMLElement>) => void;
}

const Register: FC<RegisterProps> = ({ onSubmit, handleChangeLanguages }) => {
  const { i18n } = useTranslation();
  return (
    <MainContainer type="auth">
      <SectionContainer type="header-auth">
        <Logo type="auth" />
        <SwitchLanguagesButton
          i18n={i18n}
          availableLanguages={availableLanguages}
          type="black"
          handleChangeLanguages={handleChangeLanguages}
        />
      </SectionContainer>

      <SectionContainer type="auth">
        <RegisterForm onSubmit={onSubmit} title="registration-title" />
      </SectionContainer>
    </MainContainer>
  );
};

export default Register;
