import React, { FC, useState } from 'react';
import './SwitchLanguagesButton.scss';

interface SwitchLanguagesButtonProps {
  handleChangeLanguages: (e: React.MouseEvent<HTMLElement>) => void;
  availableLanguages: object | any;
  i18n: object | any;
  type: string;
}

const SwitchLanguagesButton: FC<SwitchLanguagesButtonProps> = ({
  handleChangeLanguages,
  availableLanguages,
  i18n,
  type,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const currentLanguage = i18n.language.split('-').slice(0, 1).toString();
  return (
    <div className={`switch-button ${isOpen ? 'switch-button_type_active' : ''}`} onClick={() => setIsOpen(!isOpen)}>
      <p className={`switch-button__lang switch-button__lang_type_${type}`}>
        {currentLanguage}
        <span className={`switch-button__arrow switch-button__arrow_type_${type}`}>
          <span className={`switch-button__arrow_line switch-button__arrow_line_theme_${type}`} />
          <span className={`switch-button__arrow_line switch-button__arrow_line_theme_${type}`} />
        </span>
      </p>
      <ul
        className={`switch-button__list switch-button__list_type_${type} ${
          isOpen ? 'switch-button__list_type_open' : ''
        }`}
      >
        {availableLanguages.map((item: any) => (
          <li key={item} className="switch-button__list_item" id={item} onClick={(e) => handleChangeLanguages(e)}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SwitchLanguagesButton;
