import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { StoreStateTypes } from '../../@types/store';
import AccountPreviewUser from '../AccountPreviewUser/AccountPreviewUser';
import AuthButton from '../Buttons/AuthButton/AuthButton';
import MobileMenuButton from '../Buttons/MobileMenuButton/MobileMenuButton';
import './AccountBar.scss';

interface AccountBarProps {
  isMobile: boolean;
  toggleMenu: (e: React.MouseEvent<HTMLElement>) => void;
  locationMenu: boolean;
}

const AccountBar: FC<AccountBarProps> = ({ isMobile, toggleMenu, locationMenu }) => {
  const auth = useSelector((state: StoreStateTypes) => state.auth);

  return (
    <div className={`account-bar ${isMobile ? 'account-bar_type_mobile' : 'account-bar_type_desktop'}`}>
      {auth.isLoggedIn ? (
        <>
          {isMobile && !locationMenu ? (
            <MobileMenuButton toggleMenu={toggleMenu} />
          ) : (
            <AccountPreviewUser isMobile={isMobile} user={auth.user} toggleMenu={toggleMenu} />
          )}
        </>
      ) : (
        <>
          {isMobile && !locationMenu ? (
            <MobileMenuButton toggleMenu={toggleMenu} />
          ) : (
            <AuthButton isMobile={isMobile} toggleMenu={toggleMenu} />
          )}
        </>
      )}
    </div>
  );
};

export default AccountBar;
