export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const CHECK_USER = 'CHECK_USER';
export const AUTH_LOADING = 'AUTH_LOADING';

export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const LOGOUT = 'LOGOUT';

// USER_ACTION
// FORMS
export const FEEDBACK_FORM_LOADING = 'FEEDBACK_FORM_LOADING';
export const FEEDBACK_FORM_SUBMIT_SUCCESS = 'FEEDBACK_FORM_SUBMIT_SUCCESS';
export const FEEDBACK_FORM_SUBMIT_ERR = 'FEEDBACK_FORM_SUBMIT_ERR';
export const FEEDBACK_FORM_LOADING_ERR = 'FEEDBACK_FORM_LOADING_ERR';
// USER_INFO_UPDATE
export const UPDATE_USER_FORM_LOADING = 'UPDATE_USER_FORM_LOADING';
export const UPDATE_USER_FORM_SUCCESS = 'UPDATE_USER_FORM_SUCCESS';
export const UPDATE_USER_FORM_ERR = 'UPDATE_USER_FORM_ERR';
// USER_INFO_UPDATE
export const UPDATE_USER_AVATAR_FORM_LOADING = 'UPDATE_USER_AVATAR_FORM_LOADING';
export const UPDATE_USER_AVATAR_FORM_SUCCESS = 'UPDATE_USER_AVATAR_FORM_SUCCESS';
export const UPDATE_USER_AVATAR_FORM_ERR = 'UPDATE_USER_AVATAR_FORM_ERR';
// USER_PASSWORD_UPDATE
export const UPDATE_USER_PASSWORD_FORM_LOADING = 'UPDATE_USER_PASSWORD_FORM_LOADING';
export const UPDATE_USER_PASSWORD_FORM_SUCCESS = 'UPDATE_USER_PASSWORD_FORM_SUCCESS';
export const UPDATE_USER_PASSWORD_FORM_ERR = 'UPDATE_USER_PASSWORD_FORM_ERR';

// TOOLTIPS
// COOKIES_TOOLTIP
export const TOOLTIP_COOKIES_OPEN = 'TOOLTIP_COOKIES_OPEN';
export const TOOLTIP_COOKIES_CLOSE = 'TOOLTIP_COOKIES_CLOSE';
// STATE_TOOLTIP
export const TOOLTIP_STATE_USER_OPEN = 'TOOLTIP_STATE_USER_OPEN';
export const TOOLTIP_STATE_USER_CLOSE = 'TOOLTIP_STATE_USER_CLOSE';
