import React, { FC } from 'react';
import './AccountPreviewUser.scss';
import { Link } from 'react-router-dom';
import { IUser } from '../../@types/response/IUser';
import defaultAvatar from '../../images/icons/avatar.svg';

interface AccountPreviewUserProps {
  isMobile: boolean;
  user: IUser;
  toggleMenu: (e: React.MouseEvent<HTMLElement>) => void;
}

const AccountPreviewUser: FC<AccountPreviewUserProps> = ({ isMobile, user, toggleMenu }) => {
  return (
    <>
      {!isMobile ? (
        <Link to="/profile" className="account-preview account-preview_type_default">
          <button
            className="account-preview__button account-preview__button_type_default"
            onClick={toggleMenu}
            style={{ backgroundImage: `url(${user?.avatar || defaultAvatar})` }}
            type="button"
            aria-label="avatar"
          />
        </Link>
      ) : (
        <Link to="/profile" className="account-preview account-preview_type_mobile" onClick={toggleMenu}>
          <button
            className="account-preview__button account-preview__button_type_mobile"
            style={{ backgroundImage: `url(${user?.avatar || defaultAvatar})` }}
            type="button"
            aria-label="avatar"
          />
          <div className="account-preview__info">
            <p className="account-preview__name">{`${user?.name} ${user?.surname}`}</p>
            <p className="account-preview__email">{user?.email}</p>
          </div>
        </Link>
      )}
    </>
  );
};

export default AccountPreviewUser;
