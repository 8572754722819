import React, { FC } from 'react';
import MainContainer from '../Containers/MainContainer';
import PageContainer from '../Containers/PageContainer';
import NotFounErr from '../NotFoundErr/NotFounErr';

interface NotFoundProps {
  onBack: (e: React.MouseEvent<HTMLElement>) => void;
}

const NotFound: FC<NotFoundProps> = ({ onBack }) => {
  return (
    <PageContainer type="not-found">
      <MainContainer type="not-found">
        <NotFounErr onBack={onBack} />
      </MainContainer>
    </PageContainer>
  );
};

export default NotFound;
