import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './ModuleEquipments.scss';

interface ModuleEquipmentsProps {
  data: Array<string>;
}

const ModuleEquipments: FC<ModuleEquipmentsProps> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <ul className="module-equipments">
      <h2 className="module-equipments__title">{t('module-includes')}</h2>
      {data.map((equipment) => (
        <li className="module-equipments__item" key={equipment}>
          {t(equipment)}
        </li>
      ))}
      <hr className="module-equipments__line" />
    </ul>
  );
};

export default ModuleEquipments;
