import React, { FC, ReactNode } from 'react';

interface PageContainerProps {
  children: ReactNode;
  type: string;
}

const PageContainer: FC<PageContainerProps> = ({ children, type }) => {
  return <div className={`page page_type_${type}`}>{children}</div>;
};

export default PageContainer;
