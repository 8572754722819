import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ModuleDescriptionsListTypes } from '../../@types/module';
import './ModuleDescriptions.scss';

interface ModuleDescriptionsProps {
  title: string;
  descriptionsList: Array<ModuleDescriptionsListTypes>;
}

const ModuleDescriptions: FC<ModuleDescriptionsProps> = ({ descriptionsList, title }) => {
  const { t } = useTranslation();
  return (
    <ul className="module-descriptions">
      <h1 className="module-descriptions__title">{t(title)}</h1>
      {descriptionsList.map((item) => (
        <li className="module-descriptions__item" key={item.id}>
          {t(item.text)}
        </li>
      ))}
    </ul>
  );
};

export default ModuleDescriptions;
