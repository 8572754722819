import React, { FC } from 'react';
import terms from '../../utils/terms';
import MainContainer from '../Containers/MainContainer';
import PageContainer from '../Containers/PageContainer';
import SectionContainer from '../Containers/SectionContainer';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import TermsList from '../TermsList/TermsList';

interface TermsProps {
  handleChangeLanguages: (e: React.MouseEvent<HTMLElement>) => void;
}

const Terms: FC<TermsProps> = ({ handleChangeLanguages }) => {
  return (
    <PageContainer type="terms">
      <MainContainer type="terms">
        <SectionContainer type="terms">
          <TermsList terms={terms} handleChangeLanguages={handleChangeLanguages} />
        </SectionContainer>
      </MainContainer>
    </PageContainer>
  );
};

export default Terms;
