import {
  FEEDBACK_FORM_LOADING,
  FEEDBACK_FORM_SUBMIT_ERR,
  FEEDBACK_FORM_SUBMIT_SUCCESS,
  UPDATE_USER_AVATAR_FORM_ERR,
  UPDATE_USER_AVATAR_FORM_LOADING,
  UPDATE_USER_AVATAR_FORM_SUCCESS,
  UPDATE_USER_FORM_ERR,
  UPDATE_USER_FORM_LOADING,
  UPDATE_USER_FORM_SUCCESS,
  UPDATE_USER_PASSWORD_FORM_ERR,
  UPDATE_USER_PASSWORD_FORM_LOADING,
  UPDATE_USER_PASSWORD_FORM_SUCCESS,
  FEEDBACK_FORM_LOADING_ERR,
} from '../types/constants';

const initialUserFormsService = {
  feedbackForm: {
    isLoading: false,
    isSubmit: false,
    isSubmitError: false,
  },
  updateUserForm: {
    isLoading: false,
    isSubmit: false,
    isSubmitError: false,
  },
  updateUserAvatarForm: {
    isLoading: false,
    isSubmit: false,
    isSubmitError: false,
  },
  updateUserPasswordForm: {
    isLoading: false,
    isSubmit: false,
    isSubmitError: false,
  },
};

export default function userFormsReducer(state = initialUserFormsService, action: { type: string }) {
  const { type } = action;

  switch (type) {
    case FEEDBACK_FORM_SUBMIT_SUCCESS:
      return {
        ...state,
        feedbackForm: {
          ...state.feedbackForm,
          isLoading: false,
          isSubmit: true,
          isSubmitError: false,
        },
      };
    case FEEDBACK_FORM_SUBMIT_ERR:
      return {
        ...state,
        feedbackForm: {
          ...state.feedbackForm,
          isLoading: false,
          isSubmit: true,
          isSubmitError: true,
        },
      };
    case FEEDBACK_FORM_LOADING:
      return {
        ...state,
        feedbackForm: {
          ...state.feedbackForm,
          isLoading: true,
        },
      };
    case FEEDBACK_FORM_LOADING_ERR:
      return {
        ...state,
        feedbackForm: {
          ...state.feedbackForm,
          isLoading: false,
        },
      };
    case UPDATE_USER_FORM_SUCCESS:
      return {
        ...state,
        updateUserForm: {
          isLoading: false,
          isSubmit: true,
          isSubmitError: false,
        },
      };
    case UPDATE_USER_FORM_ERR:
      return {
        ...state,
        updateUserForm: {
          isLoading: false,
          isSubmit: true,
          isSubmitError: true,
        },
      };
    case UPDATE_USER_FORM_LOADING:
      return {
        ...state,
        updateUserForm: {
          isLoading: true,
          isSubmitError: false,
        },
      };
    case UPDATE_USER_AVATAR_FORM_SUCCESS:
      return {
        ...state,
        updateUserAvatarForm: {
          isLoading: false,
          isSubmit: true,
          isSubmitError: false,
        },
      };
    case UPDATE_USER_AVATAR_FORM_ERR:
      return {
        ...state,
        updateUserAvatarForm: {
          isLoading: false,
          isSubmit: true,
          isSubmitError: true,
        },
      };
    case UPDATE_USER_AVATAR_FORM_LOADING:
      return {
        ...state,
        updateUserAvatarForm: {
          isLoading: true,
          isSubmitError: false,
        },
      };
    case UPDATE_USER_PASSWORD_FORM_SUCCESS:
      return {
        ...state,
        updateUserPasswordForm: {
          isLoading: false,
          isSubmit: true,
          isSubmitError: false,
        },
      };
    case UPDATE_USER_PASSWORD_FORM_ERR:
      return {
        ...state,
        updateUserPasswordForm: {
          isLoading: false,
          isSubmit: true,
          isSubmitError: true,
        },
      };
    case UPDATE_USER_PASSWORD_FORM_LOADING:
      return {
        ...state,
        updateUserPasswordForm: {
          isLoading: true,
          isSubmitError: false,
        },
      };
    default:
      return state;
  }
}
